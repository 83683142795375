export type Primitive = string | boolean | number;
export type QueryParamValue = Primitive | Primitive[] | undefined;

export const buildQueryString = (params: Record<string, QueryParamValue>) => {
  const searchParams = new URLSearchParams();
  for (const key in params) {
    const value = params[key];
    if (value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          searchParams.append(key, String(item));
        });
      } else {
        searchParams.append(key, String(value));
      }
    }
  }
  return searchParams.toString();
};
