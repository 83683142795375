import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from 'src/api';
import { TPagination } from 'src/types/pagination.type';
import { downloadBlob, getFileNameFromHeaders } from 'src/utils/blob.utils';
import { buildQueryString } from 'src/utils/buildQueryString';
import { toQueryParams } from 'src/utils/pagination.utils';

import {
  BillingSummary,
  FinalizeInvoicePayload,
  InvoiceDetailedStatement,
  InvoiceDetails,
  InvoiceDocument,
  InvoiceListApiPayload,
  InvoicesListApiResponse,
  InvoiceStatementApiPayload,
  InvoiceStatementApiResponse,
  InvoiceStatementYear,
  InvoicesToSignDetails,
  IssuerIssuerListApiResponse,
  SingleInvoice,
} from './billing.types';

const DEFAULT_BILLING_PAGE_SIZE = 10;

export const billingApi = createApi({
  reducerPath: 'billingApi',
  baseQuery,
  tagTypes: ['InvoiceSummary', 'InvoiceDetails', 'BillingSummary', 'InvoicesList', 'SingleInvoice'],
  keepUnusedDataFor: 0,
  endpoints: (build) => ({
    billingSummary: build.query<BillingSummary[], { query?: string }>({
      query: ({ query }) => ({
        method: 'POST',
        url: `/invoice/billing-summary`,
        body: {
          query,
        },
      }),
      providesTags: ['BillingSummary'],
    }),
    invoicesList: build.query<InvoicesListApiResponse, InvoiceListApiPayload>({
      query: (body) => {
        return {
          method: 'POST',
          url: `/invoice/list?${toQueryParams({
            page: body.page ?? 1,
            pageSize: body.pageSize ?? DEFAULT_BILLING_PAGE_SIZE,
          })}`,
          body: body,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.items.map(({ id }) => ({ type: 'InvoiceSummary' as const, id })),
              'InvoiceSummary',
            ]
          : ['InvoiceSummary'],
    }),
    issuerWaitingInvoices: build.query<IssuerIssuerListApiResponse, TPagination>({
      query: ({ page, pageSize = 10 }) => ({
        method: 'POST',
        url: `/invoice/issuer/list?${toQueryParams({ pageSize, page })}`,
        body: {},
      }),
    }),
    invoicesToSignDetails: build.query<InvoicesToSignDetails, { ids: string[] }>({
      query: ({ ids }) => ({
        method: 'POST',
        url: '/v2/Invoice/issuer/sign/details',
        body: {
          ids,
          all: false,
        },
      }),
    }),
    finalizeInvoice: build.mutation<null, FinalizeInvoicePayload>({
      query: (body) => ({
        url: `/invoice/issuer/sign`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['InvoiceSummary'],
    }),
    requestSignatureAgain: build.mutation<null, string>({
      query: (id) => ({
        url: `/invoice/${id}/request-signatures`,
        method: 'POST',
      }),
    }),

    invoiceStatementYears: build.query<InvoiceStatementYear[], void>({
      query: () => '/invoice/statement-years',
    }),
    invoiceStatements: build.query<InvoiceStatementApiResponse, InvoiceStatementApiPayload>({
      query: (body) => ({
        url: '/invoice/statements',
        method: 'POST',
        body: {
          ...body,
          queryString: body?.query,
          includeSubmitted: true,
          includePaid: true,
        },
      }),
    }),
    invoiceStatement: build.query<InvoiceDetailedStatement, string>({
      query: (id) => `/invoice/${id}/statement`,
    }),
    invoiceDocuments: build.query<InvoiceDocument[], string>({
      query: (id) => `/invoice/${id}/documents`,
    }),
    invoiceDetails: build.query<InvoiceDetails, string>({
      query: (id) => `/invoice/${id}/details`,
      providesTags: ['InvoiceDetails'],
    }),
    invoiceDownload: build.query<
      void,
      { id: string; type?: string; params?: Record<string, string | number | boolean> }
    >({
      query: (x) => {
        const isCsv = x.type?.toLowerCase() === 'csv';
        const queryString = x.params ? buildQueryString(x.params) : '';
        const url = isCsv
          ? `/invoice/${x.id}/documents/download-invoice-sessions?${queryString}`
          : `/invoice/${x.id}/documents/download-invoice`;

        return {
          url,
          method: 'GET',
          responseHandler: async (r) => {
            if (r.status !== 200) throw new Error('Error downloading invoice');
            const fileBlob = await r.blob();
            downloadBlob(fileBlob, getFileNameFromHeaders(r.headers) ?? 'invoice.pdf');
          },
        };
      },
    }),
    getInvoice: build.query<SingleInvoice, string>({
      query: (id) => ({
        url: `/Invoice/${id}`,
        method: 'GET',
      }),
      providesTags: ['SingleInvoice'],
    }),
    invoiceReopen: build.query<void, string>({
      query: (id) => ({
        url: `/invoice/${id}/reopen`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useBillingSummaryQuery,
  useInvoicesListQuery,
  useIssuerWaitingInvoicesQuery,
  useInvoicesToSignDetailsQuery,
  useFinalizeInvoiceMutation,
  useRequestSignatureAgainMutation,
  useInvoiceStatementYearsQuery,
  useInvoiceStatementsQuery,
  useInvoiceDetailsQuery,
  useInvoiceDocumentsQuery,
  useInvoiceStatementQuery,
  useInvoiceDownloadQuery,
  useInvoiceReopenQuery,
  useGetInvoiceQuery,
  util: { invalidateTags: invalidateBillingTags },
} = billingApi;
