import { useLocation, useParams } from 'react-router-dom';
import Loader from 'src/components/Loader';

import { useInvoiceDownloadQuery } from './billingApi';

const BillingInvoicesDownload = () => {
  const { id: invoiceId = '', type: fileType = '' } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(queryParams.entries());

  const { isFetching } = useInvoiceDownloadQuery({
    id: invoiceId,
    type: fileType,
    params,
  });

  const DownloadLoader = () => (
    <div className="flex items-center gap-1">
      <div>Downloading</div>
      <Loader />
    </div>
  );

  return (
    <div className="pl-10 pt-10">
      {isFetching ? <DownloadLoader /> : <div>File downloaded</div>}
    </div>
  );
};

export default BillingInvoicesDownload;
